const dot = require("dot-object");
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  Stack,
  IconButton,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import hooks from "./hooks";
import parser from "./parser";
import { getData, questionnaireVersion1 } from "./util";

function View({ question, context }) {
  const isVersion1 = questionnaireVersion1.includes(getData("questionnaireID"));
  const [results, setResults] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || [],
  });

  question.input = parser.parse(question.input, context);

  useEffect(() => {
    if (results) return;

    if (isVersion1) {
      if (!question.input.fields) {
        question.input.fields = [
          {
            key: "amount",
            required: question.input.required,
            default: formValues.default,
          },
        ];

        if (question.input.nights_occupied) {
          question.input.fields.push({
            key: "nights_occupied",
            required: question.input.required,
            default: formValues.default,
            readonly: formValues.readonly,
          });
        }

        if (question.input.nights_available) {
          question.input.fields.push({
            key: "nights_available",
            required: question.input.required,
            default: formValues.default,
            readonly: formValues.readonly,
          });
        }

        if (question.input.avg_nightly_rate) {
          question.input.fields.push({
            key: "avg_nightly_rate",
            required: question.input.required,
            default: formValues.default,
            readonly: formValues.readonly,
          });
        }
      }

      if (!formValues.output.length) {
        let tempFormValues = {
          output: [],
        };

        context.pick("paymentDates")?.forEach((period) => {
          question.input.platforms.forEach((platform) => {
            const item = { ...period };
            item.period_start = buildDate(
              context.pick("customer.taxablePeriod.revenueReportFrequency"),
              item
            );
            item.period_end = getPeriodEnd(item.period_start, item.month);
            item.site = platform === "null" ? null : platform;
            item.amount = formValues.default;

            question.input.fields.forEach((field) => {
              item[field.key] = field.default;
            });
            tempFormValues.output.push(item);
          });
        });
        setFormValues((previousValues) => ({
          ...previousValues,
          output: tempFormValues.output,
        }));
      }
    } else {
      if (!question.input.fields) {
        question.input.fields = [{ key: "amount" }];
      }

      hooks
        .gql(
          {
            query: `
        query($jurisdiction_id:ID! $foreign_type:FilingType! $foreign_id:ID) {
          platforms { hits { id name } }

          filingPeriods(jurisdiction_id:$jurisdiction_id foreign_type:$foreign_type foreign_id:$foreign_id) {
            filing_frequency
            period { start_at end_at }
            periods { start_at end_at }
          }
        }
      `,
            variables: parser.parse(question.input.subject, context),
          },
          context
        )
        .then((res) => {
          if (res.errors) {
            setFormErrors((previousValues) => ({
              ...previousValues,
              queryFailure: res.errors[0].message,
            }));
            context.assign({
              canSubmit: false,
            });
            return;
          }
          setResults(res);

          let fields = {};
          question.input.fields.forEach((field) => {
            if (field.default) {
              fields[field.key] = field.default;
            }
          });
          let defaultValue = [];
          res.data?.filingPeriods?.periods.forEach((period) => {
            question.input.platforms.forEach((platform_id) => {
              defaultValue.push(
                Object.assign(
                  {
                    period: {
                      start_at: period.start_at,
                      end_at: period.end_at,
                    },
                    platform_id: platform_id === "null" ? null : platform_id,
                    foreign_id: parser.getContextValue(
                      question.input.subject.foreign_id,
                      context
                    ),
                  },
                  fields
                )
              );
            });
          });

          if (formValues.output.length === 0) {
            let values = { ...formValues };
            values.output = defaultValue;
            setFormValues(values);
          }
          context.assign({
            canSubmit: true,
          });
        });
    }
  }, [results]);

  const buildDate = (interval, itemx) => {
    const item = { ...itemx };
    const quarterStartMonths = { 1: "01", 2: "04", 3: "07", 4: "10" };
    const month = quarterStartMonths[item.quarter];

    switch (interval) {
      case "monthly":
        item.month = String(item.month).padStart(2, "0");
        return `${item.year}-${item.month}-01`;

      case "quarterly":
      default:
        return `${item.year}-${month}-01`;
    }
  };

  const getPeriodEnd = (startDate, isMonth) => {
    const date = new Date(startDate);
    if (isMonth) {
      // End of the month
      date.setMonth(date.getMonth() + 1);
      date.setDate(0);
    } else {
      // End of the quarter
      const month = date.getMonth();
      const endMonth = month + (3 - (month % 3));
      date.setMonth(endMonth);
      date.setDate(0);
    }
    return date.toISOString().split("T")[0];
  };

  const getPlatformById = (id) => {
    const res = results?.data.platforms.hits.filter((o) => o.id === id);
    return res.length > 0 ? res[0] : false;
  };

  const removeInterval = (i) => {
    let values = { ...formValues };
    values.output.splice(i, 1);
    setFormValues(values);
    context.assign({
      value: values.output,
    });
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const rawValue = removeCommas(value);
    if (!isNaN(rawValue)) {
      let values = { ...formValues };
      dot.str(name, rawValue !== "" ? parseInt(rawValue) : rawValue, values);
      setFormValues(values);
      context.assign({
        value: values.output,
      });
    }
  };

  const formatNumberWithCommas = (number) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (number) => {
    return number.replace(/,/g, "");
  };

  const renderFilingItem = (filing, i) => {
    const platform_id = isVersion1 ? filing.platform : filing.platform_id;
    const start_at = isVersion1 ? filing.period_start : filing.period.start_at;
    const end_at = isVersion1 ? filing.period_end : filing.period.end_at;
    const filing_frequency = isVersion1
      ? context.pick("customer.taxablePeriod.revenueReportFrequency")
      : results.data.filingPeriods?.filing_frequency;

    return (
      <div key={`filing-${i}`}>
        <h2>
          {platform_id && [null, "OTHER"].indexOf(platform_id) === -1 && (
            <span>{question.properties.locale.other_placeholder} </span>
          )}
          {platform_id && [null, "OTHER"].indexOf(platform_id) > -1 && (
            <span>{getPlatformById(platform_id).name} </span>
          )}
          {dayjs(start_at).format("MMMM YYYY")}
          {filing_frequency.toUpperCase() === "QUARTERLY" && (
            <span>
              {" "}
              {isVersion1 ? "to" : "-"} {dayjs(end_at).format("MMMM YYYY")}
            </span>
          )}
        </h2>

        <Stack direction={{ xs: "column", sm: "row" }}>
          {question.input.fields.map((field, fieldId) => (
            <FormControl error fullWidth key={fieldId}>
              <TextField
                name={`output.${i}.${field.key}`}
                type={
                  field.key === "amount" || field.key === "avg_nightly_rate"
                    ? "text"
                    : "number"
                }
                label={question.properties.locale[field.key]}
                variant="filled"
                value={formatNumberWithCommas(formValues.output[i][field.key])}
                onChange={handleInputChange}
                required={field.required}
                readOnly={field.readonly}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9,]*",
                  style: { fontSize: "22px" },
                }}
                autoComplete="off"
                aria-describedby={`filing-${i}-${field}-helper-text`}
                InputProps={
                  field.key === "amount" || field.key === "avg_nightly_rate"
                    ? {
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginRight: "2px" }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                color: "#000000cf",
                              }}
                            >
                              $
                            </span>
                          </InputAdornment>
                        ),
                      }
                    : {}
                } // Masking the field with $
              />
              <FormHelperText
                id={`filing-${i}-${field}-helper-text`}
                style={{ fontSize: "0.9rem" }}
              >
                {formErrors.text}
              </FormHelperText>
            </FormControl>
          ))}

          {context.get().admin === true && ( // TODO: use scopes not `admin`
            <IconButton onClick={() => removeInterval(i)}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          )}
        </Stack>
      </div>
    );
  };

  return (
    <div>
      {formValues &&
        formValues.output.map((filing, i) =>
          isVersion1 && filing.paid ? null : renderFilingItem(filing, i)
        )}

      {formErrors.queryFailure && (
        <FormHelperText error className="error-message">
          {formErrors.queryFailure}
        </FormHelperText>
      )}
    </div>
  );
}

export default View;
